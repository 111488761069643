export const ZAR = (v: number, maximumFractionDigits: number) =>
  Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    currencyDisplay: 'symbol',
    maximumFractionDigits: maximumFractionDigits
  }).format(v)

export const dateFormat = (date: Date, options?: Intl.DateTimeFormatOptions) =>
  Intl.DateTimeFormat('en-ZA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...options
  }).format(date)

export const timeFormat = (date: Date, options?: Intl.DateTimeFormatOptions) =>
  Intl.DateTimeFormat('en-ZA', {
    hour: 'numeric',
    minute: 'numeric',
    ...options
  }).format(date)

export const dateTimeFormat = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) =>
  Intl.DateTimeFormat('en-ZA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options
  }).format(date)
